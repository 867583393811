@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.slick-dots li.slick-active button:before {
    color:rgb(255, 253, 253) !important;
    opacity: 1;

}
.slick-dots li{
    margin:0 2px;
}
.slick-dots li button:before {
    font-size: 11px;
    
    color:rgba(255, 255, 255, 0.884) !important;

}
.slick-prev:before, .slick-next:before{
    color:transparent;
   
}
.slick-next:before{
    background: url('/public/arrownext.png') no-repeat center;

}

.slick-prev:before{
   
    background: url('/public/arrowprev.png') no-repeat center;
}

