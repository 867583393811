@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
input[type=checkbox] {
    position: relative;
	border: 1px solid #C8C8C8;
	background: none;
	cursor: pointer;
	line-height: 0;
	outline: 0;
	vertical-align: text-top;
	height: 17px;
	width: 17px;
	-webkit-appearance: none;
    opacity: .5;
    padding: 2px;
}

input[type=checkbox]:hover {
  opacity: 1;
}

input[type=checkbox]:checked {
  background-color: #2668B2;
  opacity: 1;
  border: none;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 2px;
  height: 8px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  /* margin: -1px -1px -1px 10px; */
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
          transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
table.dashboard {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.9rem;
    margin-top: 1rem;
    background-color: white;
    border: 1px solid #E3E3E3;
    color: #484848;
}
  
table.dashboard td, table.dashboard th {
    border: 1px solid #ddd;
    padding: 1.5rem 1rem;
    background-color: white;
    border: 1px solid #E3E3E3;
}

table.dashboard tr:nth-child(even){background-color: #f2f2f2;}

/* table.dashboard tr:hover {background-color: #ddd;} */

table.dashboard th {
    text-align: center;
    background-color: #ffffff;
    color: black;
    font-weight: 500;
}

table.dashboard td {
    font-size: 0.9rem;
    background-color: white;
    border: 1px solid #E3E3E3;
    margin-left: 1rem;
}
.slick-dots li.slick-active button:before {
    color:rgb(255, 253, 253) !important;
    opacity: 1;

}
.slick-dots li{
    margin:0 2px;
}
.slick-dots li button:before {
    font-size: 11px;
    
    color:rgba(255, 255, 255, 0.884) !important;

}
.slick-prev:before, .slick-next:before{
    color:transparent;
   
}
.slick-next:before{
    background: url('/public/arrownext.png') no-repeat center;

}

.slick-prev:before{
   
    background: url('/public/arrowprev.png') no-repeat center;
}


