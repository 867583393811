input[type=checkbox] {
    position: relative;
	border: 1px solid #C8C8C8;
	background: none;
	cursor: pointer;
	line-height: 0;
	outline: 0;
	vertical-align: text-top;
	height: 17px;
	width: 17px;
	-webkit-appearance: none;
    opacity: .5;
    padding: 2px;
}

input[type=checkbox]:hover {
  opacity: 1;
}

input[type=checkbox]:checked {
  background-color: #2668B2;
  opacity: 1;
  border: none;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 2px;
  height: 8px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  /* margin: -1px -1px -1px 10px; */
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}