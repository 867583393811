table.dashboard {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.9rem;
    margin-top: 1rem;
    background-color: white;
    border: 1px solid #E3E3E3;
    color: #484848;
}
  
table.dashboard td, table.dashboard th {
    border: 1px solid #ddd;
    padding: 1.5rem 1rem;
    background-color: white;
    border: 1px solid #E3E3E3;
}

table.dashboard tr:nth-child(even){background-color: #f2f2f2;}

/* table.dashboard tr:hover {background-color: #ddd;} */

table.dashboard th {
    text-align: center;
    background-color: #ffffff;
    color: black;
    font-weight: 500;
}

table.dashboard td {
    font-size: 0.9rem;
    background-color: white;
    border: 1px solid #E3E3E3;
    margin-left: 1rem;
}